import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormCheck,
  ModalFooter,
} from "react-bootstrap";
import Notification from "../components/Notification";
import PaginationComp from "../components/PaginationComp";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Validator from "fastest-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faEdit,
  faList,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";
import Header from "../layouts/Header";
import {
  listPackageRequest,
  createPackageRequest,
} from "../services/packageService";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";

export default function PackageManagement() {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  const [packageName, setPackageName] = useState("");
  const [packageStatus, setPackageStatus] = useState("all");
  const [isInit, setIsInit] = useState("false");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [totalItem, setTotalItem] = useState(0);

  //Add Package
  const [pkgName, setPkgName] = useState("");
  const [pkgCode, setPkgCode] = useState("");
  const [pkgShortDesc, setPkgShortDesc] = useState("");
  const [pkgLongDesc, setPkgLongDesc] = useState("");
  const [activeDate, setActiveDate] = useState();
  const [showAddPkgDialog, setShowAddPkgDialog] = useState(false);

  const loadPackages = async () => {
    const data = await listPackageRequest(
      packageName,
      packageStatus,
      pageIndex,
      pageSize
    );
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }
    setPackages(data.items);
    setTotalItem(data.total);
  };

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };
  const closeNotification = () => {
    setShowToast(false);
  };
  const handlePaginationCallback = async (pageSize, pageIndex) => {
    setPageIndex(pageIndex);
  };
  useEffect(() => {
    if (isInit) {
      loadPackages();
    }
  }, [isInit, packageStatus]);

  if (!isInit) {
    setIsInit(true);
  }

  const onStatusChange = (event) => {
    if (event.target.checked) {
      setPackageStatus(event.target.value);
    }
  };

  const editPackage = (packageId) => {
    navigate("packageDetails?id=" + packageId);
  };

  const onAddPackageClick = () => {
    setShowAddPkgDialog(true);
  };

  const onCloseAddPkgDialog = () => {
    setPkgName("");
    setPkgCode("");
    setPkgShortDesc("");
    setPkgLongDesc("");
    setActiveDate("");
    setShowAddPkgDialog(false);
  };

  const validator = new Validator();
  const packageSchema = {
    packageName: "string",
    shortDesc: "string",
    longDesc: "string",
    packageCode: "string",
  };

  const checkPkg = validator.compile(packageSchema);

  const onPkgNamechange = (e) => {
    setPkgName(e.target.value);
    let slug = toSlug(e.target.value);
    slug = slug.substr(0, 24);
    setPkgCode(slug);
  };

  const addNewPackage = async () => {
    let aDate = new Date(activeDate);
    let aD = DateTime.fromJSDate(aDate).toISODate();

    let pkg = {
      packageName: pkgName,
      packageCode: pkgCode,
      shortDesc: pkgShortDesc,
      longDesc: pkgLongDesc,
      activeDate: aD,
      packageStatus: "deactived",
    };

    const result = checkPkg(pkg);
    if (result.length > 0) {
      let messages = [];
      for (let i = 0; i < result.length; i++) {
        messages.push(result[i].message);
      }
      displayNotification(messages, "danger");
      return;
    }

    const data = await createPackageRequest(pkg);
    if (data.isError) {
      displayNotification("Can not create Package", "danger");
      return;
    }

    displayNotification("Create Package successfully", "success");
    if (pageIndex !== 0) {
      setPageIndex(0);
    } else {
      loadPackages();
    }
    onCloseAddPkgDialog();
  };

  const toSlug = (str) => {
    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str.replace(/[đĐ]/g, "d");

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, "-");

    // Xóa ký tự - liên tiếp
    str = str.replace(/-+/g, "-");

    // xóa phần dư - ở đầu & cuối
    str = str.replace(/^-+|-+$/g, "");

    // return
    return str;
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row style={{ marginTop: "10px" }}>
            <Col md={2}>Seach Package:</Col>
            <Col md={4}>
              <FormControl
                type="text"
                placeholder="Package name"
                onChange={(e) => setPackageName(e.target.value)}
              />
            </Col>
            <Col md={2}>
              <Button
                variant="success"
                onClick={(e) => {
                  if (pageIndex !== 0) {
                    setPageIndex(0);
                  } else {
                    loadPackages();
                  }
                }}
              >
                Search
              </Button>
            </Col>
          </Row>

          <Row style={{ marginTop: "30px" }}>
            <Col md={2}>Filter:</Col>
            <Col md={8}>
              <FormCheck
                onChange={onStatusChange}
                type="radio"
                value="all"
                label="All"
                inline
                defaultChecked
                name="package-status"
              />
              <FormCheck
                onChange={onStatusChange}
                type="radio"
                value="actived-ready"
                label="Actived-Ready"
                inline
                name="package-status"
              />
              <FormCheck
                onChange={onStatusChange}
                type="radio"
                value="actived-pending"
                label="Actived-Pending"
                inline
                name="package-status"
              />
              <FormCheck
                onChange={onStatusChange}
                type="radio"
                value="deactived"
                label="Deactived"
                inline
                name="package-status"
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={10}></Col>
            <Col md={2} style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={onAddPackageClick}>
                Add new package
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md={2} className="fw-bold">
              Total: {totalItem}
            </Col>
            <Col md={10}></Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <Table bordered striped responsive>
                <thead className="table-primary">
                  <tr>
                    <th>No.</th>
                    <th>Created at</th>
                    <th>Package id</th>
                    <th>Package code</th>
                    <th>Package name</th>
                    <th>Status</th>
                    <th>Display Order</th>
                    <th>No. of sub-packages</th>
                    <th style={{ textAlign: "center" }}>View/Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {packages && (
                    <>
                      {packages.map((pkg, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + pageIndex + 1}</td>
                            <td>{pkg.createdAt}</td>
                            <td>{pkg.id}</td>
                            <td>{pkg.packageCode}</td>
                            <td>{pkg.packageName}</td>
                            <td>{pkg.packageStatus}</td>
                            <td>{pkg.displayIndex}</td>
                            <td>{pkg.packageItems.length}</td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                                onClick={(e) => editPackage(pkg.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col md={2}></Col>
            <Col md={4}>
              <PaginationComp
                total={totalItem}
                pageSize={pageSize}
                callback={handlePaginationCallback}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />

      <Modal
        show={showAddPkgDialog}
        onHide={onCloseAddPkgDialog}
        size="lg"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Add new package
        </ModalHeader>

        <ModalBody>
          <Container>
            <Row className="align-items-center">
              <Col md={3}>Package name:</Col>
              <Col md={9}>
                <FormControl type="text" onChange={onPkgNamechange} />
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Package code:</Col>
              <Col md={9}>
                <FormControl type="text" value={pkgCode} readOnly/>
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Short description</Col>
              <Col md={9}>
                <FormControl
                  type="text"
                  onChange={(e) => setPkgShortDesc(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Long description</Col>
              <Col md={9}>
                <ReactQuill
                  theme="snow"
                  onChange={(e) => setPkgLongDesc(e)}
                  className="border mt-3 rounded"
                />
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Active date</Col>
              <Col md={9}>
                <DatePicker value={activeDate} onChange={setActiveDate} />
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={addNewPackage}>
            Add
          </Button>

          <Button variant="secondary" onClick={onCloseAddPkgDialog}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
