import React, { useEffect, useRef, useState } from "react";
import Header from "../layouts/Header";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Table,
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormSelect,
  FormCheck,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getPackageDetailRequest,
  updatePackageRequest,
  createPackageItemRequest,
  assignCvTemplateRequest,
} from "../services/packageService";
import Notification from "../components/Notification";
import { DateTime } from "luxon";
import { uploadImageRequest } from "../services/documentService";
import { ClockLoader } from "react-spinners";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Validator from "fastest-validator";
import FormBuilderComp from "../components/FormBuilderComp";
import { getCvTemplateRequest, listCvTemplateRequest } from "../services/cvService";
import DatePicker from "react-date-picker";
import PaginationComp from "../components/PaginationComp";
import { faEye } from "@fortawesome/free-regular-svg-icons";



const PackageDetail = () => {
  //Modal Edit Dialog
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [editValue, setEditValue] = useState("");
  const [paramsIndex, setParamsIndex] = useState("");
  const [params, setParams] = useState([]);

  const [loading, setLoading] = useState(false);

  //Package
  const [packageId, setPackageId] = useState("");

  //Modal Add Sub-package
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [itemShortDesc, setItemShortDesc] = useState("");
  const [itemLongDesc, setItemLongDesc] = useState("");
  const [itemDisplayIndex, setItemDisplayIndex] = useState(0)

  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [pkg, setPkg] = useState({}); //package data
  const [pkgItems, setPkgItems] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [createdBy, setCreatedBy] = useState("");

  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const [showUploadImage, setShowUploadImage] = useState(false);
  const uploadImageRef = useRef(null);
  const formBuilderCompRef = useRef(null);
  const [image, setImage] = useState(
    "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a425398cc%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a425398cc%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
  );
  const [imageType, setImageType] = useState("small");
  const [uploadedImage, setUploadedImage] = useState("");

  const [cvTemplateDialog, setCvTemplateDialog] = useState(false);
  const [cvAction, setCvAction] = useState("new");
  const [cvTemplateId, setCvTemplateId] = useState("");
  const [templates, setTemplates] = useState([])
  const [cvPageIndex, setCvPageIndex] = useState(0);
  const [cvPageSize, setCvPageSize] = useState(20);
  const [cvTotalItem, setCvTotalItem] = useState(0);
  const [templateName, setTemplateName] = useState("");

  const [pkgStatusDialog, setPkgStatusDialog] = useState(false);
  const [pkgStatus, setPkgStatus] = useState("actived-ready")

  const [pkgActiveDateDialog, setPkgActiveDateDialog] = useState(false);
  const [pkgActiveDate, setPkgActiveDate] = useState(new Date());

  const [viewCvTemplateDialog, setViewCvTemplateDialog] = useState(false);
  const [cvTemplate, setCvTemplate] = useState({});

  const closeViewCvTemplateDialog = () => {
    setViewCvTemplateDialog(false);
  }


  const closePkgStatusDialog = async() => {
    setPkgStatusDialog(false);
    await getPackageDetail();
  }

  const closePkgActiveDateDialog = async() => {
    setPkgActiveDateDialog(false);
    await getPackageDetail();
  }

  

  const closeCvTemplateDialog = async() =>{
    setCvTemplateDialog(false);
    setCvAction("new");
    setCvTemplateId("");
    setTemplateName("");
    await getPackageDetail();
  }

  const closeShowUploadImage = async () => {
    setShowUploadImage(false);
    setImageType("small");
    setUploadedImage("");
    setImage(
      "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a425398cc%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a425398cc%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
    );
    await getPackageDetail();
  };

  //Modal Add Item Dialog
  const onCloseAddItemDialog = () => {
    setItemName("");
    setItemCode("");
    setItemShortDesc("");
    setItemLongDesc("");
    setShowAddItemDialog(false);
  };

  const onAddItemClick = () => {
    setShowAddItemDialog(true);
  };

  const onItemNameChange = (e) => {
    setItemName(e.target.value);
    let slug = toSlug(e.target.value);
    slug = slug.substr(0, 24);
    setItemCode(slug);
  };

  const addNewItem = async () => {
    let item = {
      itemName: itemName,
      itemCode: itemCode,
      shortDesc: itemShortDesc,
      longDesc: itemLongDesc,
      packageId: packageId,
      displayIndex: itemDisplayIndex
    };
    const result = checkItem(item);
    if (result.length > 0) {
      let messages = [];
      for (let i = 0; i < result.length; i++) {
        messages.push(result[i].message);
      }
      displayNotification(messages, "danger");
      return;
    }

    const data = await createPackageItemRequest(item);
    if (data.isError) {
      displayNotification("Can not create Sub-Package", "danger");
      return;
    }
    displayNotification("Create Sub-Package successfully", "success");

    getPackageDetail();
    onCloseAddItemDialog();
  };

  //Modal Edit Dialog
  const onCloseEditDialog = async () => {
    setShowEditDialog(false);
    await getPackageDetail();
  };

  const onEditClick = (header, value, index) => {
    setModalHeader(header);
    setEditValue(value);
    setParamsIndex(index);
    setShowEditDialog(true);
  };

  const onEditValueChange = (e) => {
    setEditValue(e.target.value);

    let newParams = [null, null, null, null];
    if (paramsIndex === 0) {
      let slug = toSlug(e.target.value);
      slug = slug.substr(0, 24);
      newParams[1] = slug;
      newParams[paramsIndex] = e.target.value;
    } else {
      newParams[paramsIndex] = e.target.value;
    }

    setParams(newParams);
  };

  const onLongDescChange = (e) => {
    setEditValue(e);
    setParams([null, null, null, e]);
  };

  const onUpdateClick = async () => {
    let pkg = {
      packageName: params[0] || "",
      packageCode: params[1] || "",
      shortDesc: params[2] || "",
      longDesc: params[3] || "",
      displayIndex: params[4] || "",
    };
    const result = checkPkg(pkg);
    if (result.length > 0) {
      let messages = [];
      for (let i = 0; i < result.length; i++) {
        messages.push(result[i].message);
      }
      displayNotification(messages, "danger");
      return;
    }

    let data = null;
    data = await updatePackageRequest(
      packageId,
      params[0],
      params[1],
      params[2],
      params[3],
      params[4],
      null,
      null,
      null
    );

    if (!data.result) {
      displayNotification(`Can not update ${modalHeader}`, "danger");
      return;
    }

    displayNotification(`Update ${modalHeader} successfully`, "success");
    onCloseEditDialog();
  };

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };
  const closeNotification = () => {
    setShowToast(false);
    setLoading(false);
  };

  const getPackageDetail = async () => {
    setLoading(true);
    const data = await getPackageDetailRequest(packageId);
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }
    setPkg(data);
    setPkgStatus(data.packageStatus);
    if(data.activeDate){
      setPkgActiveDate(data.activeDate);
    }else{
      setPkgActiveDate(new Date());
    }
    if(data.user){
      setCreatedBy(data.user.fullName ? data.user.fullName : (data.user.email ? data.user.email : 
        (data.user.phoneNumber ? data.user.phoneNumber : data.user.id)))
    }
    if (data.packageItems) {
      setPkgItems(data.packageItems);
    }
    setLoading(false);
  };

  const assignTemplate = async() => {
    const data = await assignCvTemplateRequest(packageId, cvTemplateId);
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }
    closeCvTemplateDialog();
  }

  useEffect(() => {
    if (isInit) {
      getPackageDetail();
    }
  }, [isInit]);

  if (!isInit) {
    if (queryParams.get("id")) {
      setPackageId(queryParams.get("id"));
    }
    setIsInit(true);
  }

  const clickUploadImage = () => {
    uploadImageRef.current.click();
  };

  const onFileChange = async (event) => {
    setLoading(true);
    // let width = 300;
    // let height = 300;
    // if (imageType === "large") {
    //   width = 700;
    //   height = 700;
    // }
    let data = await uploadImageRequest(event.target.files[0]/*, width, height*/);
    if (data.isError) {
      displayNotification("Can not upload image", "danger");
      return;
    }
    setUploadedImage(data.path);
    setImage(data.path);
    setLoading(false);
  };

  const savePackageImage = async () => {
    if (uploadedImage === "") {
      displayNotification("You must choose an image to continue", "danger");
      return;
    }
    let data = null;
    if (imageType === "small") {
      data = await updatePackageRequest(
        packageId,
        null,
        null,
        null,
        null,
        null,
        uploadedImage,
        null,
        null,
        null
      );
    } else {
      data = await updatePackageRequest(
        packageId,
        null,
        null,
        null,
        null,
        null,
        null,
        uploadedImage,
        null,
        null
      );
    }
    if (data.isError) {
      displayNotification("Can not save image for package", "danger");
      return;
    }
    displayNotification("Save package's image successfully", "success");
    closeShowUploadImage();
  };

  const validator = new Validator();
  const packageSchema = {
    packageName: "string",
    shortDesc: "string",
    longDesc: "string",
    packageCode: "string",
  };
  const checkPkg = validator.compile(packageSchema);

  const itemSchema = {
    itemName: "string",
    shortDesc: "string",
    longDesc: "string",
    itemCode: "string",
  };
  const checkItem = validator.compile(itemSchema);

  const toSlug = (str) => {
    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str.replace(/[đĐ]/g, "d");

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, "-");

    // Xóa ký tự - liên tiếp
    str = str.replace(/-+/g, "-");

    // xóa phần dư - ở đầu & cuối
    str = str.replace(/^-+|-+$/g, "");

    // return
    return str;
  };

  // const getCvTemplate = async(templateId) => {
  //   setLoading(true)
  //   const data = await getCvTemplateRequest(templateId);
  //   if(data.isError){
  //     displayNotification(data.msg, "danger");
  //     return;
  //   }
  //   setCvTemplate(JSON.parse(data.template));
  //   setCvTemplateId(data.id);
  //   setLoading(false);
  // }

  const openCvDialog = async(templateId) => {
    // if(templateId){
    //   await getCvTemplate(templateId);      
    // }
    setCvTemplateDialog(true);
  }

  const updatePackageStatus = async() => {
    setLoading(true)
    const data = await updatePackageRequest(pkg.id, null, null, null, null, null, null, null, pkgStatus, null);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return
    }
    displayNotification("Package's status is updated", "success");
    closePkgStatusDialog();
  }
  const updatePackageActiveDate = async() => {
    setLoading(true);
    const data = await updatePackageRequest(pkg.id, null, null, null, null, null, null, null, "actived-pending", pkgActiveDate);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return
    }
    displayNotification("Package's active date is updated", "success");
    closePkgActiveDateDialog()
  }

  const searchTemplate = async() => {
    const data = await listCvTemplateRequest(templateName, "active", cvPageIndex, cvPageSize);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setCvTotalItem(data.total);
    setTemplates(data.items)
  }

  const handleCvPaginationCallback = async (pageSize, pageIndex) => {
    setCvPageIndex(pageIndex);
  };

  const getTemplate = async(id) => {
    const data = await getCvTemplateRequest(id);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setCvTemplate(data.template);
    setViewCvTemplateDialog(true);
  }

  useEffect(() => {
    searchTemplate()
  }, [cvPageIndex]);


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={12}></Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="" onClick={() => navigate(`/service/package`)}>
                    Package management
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Package detail
                </li>
              </ol>
            </nav>
          </Row>

          <Row className="mt-3">
            <b className="fs-5">Package Id: {pkg.id}</b>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Table bordered striped responsive>
                <thead className="table-primary">
                  <tr style={{ fontWeight: "bold" }}>
                    <th>Content</th>
                    <th>Detail</th>
                    <th style={{ textAlign: "center" }}>Edit</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Package name</td>
                    <td>{pkg.packageName}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Package name", pkg.packageName, 0)
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Created at</td>
                    <td>
                      {DateTime.fromISO(pkg.createdAt).toISODate(
                        DateTime.DATE_SHORT
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>

                  <tr>
                    <td>Created by</td>
                    <td>
                      {createdBy}
                    </td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>

                  <tr>
                    <td>Package code</td>
                    <td>{pkg.packageCode}</td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>

                  <tr>
                    <td>Status</td>
                    <td>{pkg.packageStatus}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => setPkgStatusDialog(true)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>CV's template</td>
                    <td>
                      {pkg.packageCv?.template?.id}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => {
                            if(pkg.packageCv){
                              setCvAction("edit");
                              openCvDialog(pkg.packageCv.template.id);
                            }else{
                              openCvDialog()
                            }
                            
                          }
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Images (small)</td>
                    <td>
                      {pkg.smallImage && (
                        <Image
                          width={150}
                          height={150}
                          thumbnail
                          src={pkg.smallImage}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={(e) => {
                          setImageType("small");
                          setShowUploadImage(true);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Images (large)</td>
                    <td>
                      {pkg.largeImage && (
                        <Image
                          width={150}
                          height={150}
                          thumbnail
                          src={pkg.largeImage}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={(e) => {
                          setImageType("large");
                          setShowUploadImage(true);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Short description</td>
                    <td>{pkg.shortDesc}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Short description", pkg.shortDesc, 2)
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Long description</td>
                    <td dangerouslySetInnerHTML={{ __html: pkg.longDesc }}></td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Long description", pkg.longDesc, 3)
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Activated date</td>
                    <td>
                      {pkg.activeDate
                        ? DateTime.fromISO(pkg.activeDate).toISODate(
                            DateTime.DATE_SHORT
                          )
                        : ""}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => setPkgActiveDateDialog(true)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Display Order</td>
                    <td>
                      {pkg.displayIndex}
                    </td>
                    <td style={{ textAlign: "center" }}>
                    <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Display Order", pkg.displayIndex, 4)
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mt-3">
            <b className="fs-5">Sub-packages Detail:</b>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={10}></Col>
            <Col md={2} style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={onAddItemClick}>
                Add sub-package
              </Button>
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Created at</th>
                    <th>Sub-package id</th>
                    <th>Sub-package code</th>
                    <th>Status</th>
                    <th>Sub-package name</th>
                    <th>Unit</th>
                    <th>Price</th>
                    <th>Display Order</th>
                    <th style={{ textAlign: "center" }}>View/Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {pkgItems && (
                    <>
                      {pkgItems.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.id}</td>
                            <td>{item.itemCode}</td>
                            <td>{item.itemStatus}</td>
                            <td>{item.itemName}</td>
                            <td>{item.unit}</td>
                            <td>
                              {item.price || item.price > 0
                                ? item.price
                                : "free"}
                            </td>
                            <td>
                              {item.displayIndex}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                                onClick={() =>
                                  navigate(
                                    `/service/subpackage/subPackageDetail?id=${item.id}`
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />

      <Modal
        show={showUploadImage}
        onHide={closeShowUploadImage}
        size="lg"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Upload image
        </ModalHeader>
        <ModalBody>
          <Container fluid="md">
            <Row style={{ marginTop: "10px" }}>
              <Col md={3}>Choose an image:</Col>
              <Col md={5}>
                <Image onClick={clickUploadImage} thumbnail src={image} />
                <FormControl
                  type="file"
                  style={{ visibility: "hidden" }}
                  ref={uploadImageRef}
                  accept=".jpg, .jpeg, .png"
                  onChange={onFileChange}
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={savePackageImage}>
            Save
          </Button>
          <Button variant="secondary" onClick={closeShowUploadImage}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal Edit Dialog */}
      <Modal
        show={showEditDialog}
        onHide={onCloseEditDialog}
        size="lg"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Edit {modalHeader}
        </ModalHeader>

        <ModalBody>
          <Container>
            <Row className="align-items-center">
              <Col md={3}>{modalHeader}:</Col>
              <Col md={9}>
                {modalHeader === "Long description" ? (
                  <ReactQuill
                    theme="snow"
                    onChange={onLongDescChange}
                    value={editValue}
                    className="border mt-3 rounded"
                  />
                ) : (
                  <FormControl
                    type="text"
                    onChange={onEditValueChange}
                    value={editValue}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={onUpdateClick}>
            Update
          </Button>

          <Button variant="secondary" onClick={onCloseEditDialog}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal add sub-package */}
      <Modal
        show={showAddItemDialog}
        onHide={onCloseAddItemDialog}
        size="lg"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Add Sub-package
        </ModalHeader>

        <ModalBody>
          <Container>
            <Row className="align-items-center">
              <Col md={3}>Sub-package name:</Col>
              <Col md={9}>
                <FormControl type="text" onChange={onItemNameChange} />
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Sub-package code:</Col>
              <Col md={3}>
                <FormControl
                  type="text"
                  onChange={onEditValueChange}
                  value={itemCode}
                  readOnly
                />
              </Col>
              <Col md={3}>Display Order:</Col>
              <Col md={3}>
                <FormControl type="number" defaultValue={0} onChange={e => setItemDisplayIndex(e.target.value)}/>
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Short description:</Col>
              <Col md={9}>
                <FormControl
                  type="text"
                  onChange={(e) => setItemShortDesc(e.target.value)}
                />
              </Col>
            </Row>

            <Row className="align-items-center mt-4">
              <Col md={3}>Long description:</Col>
              <Col md={9}>
                <ReactQuill
                  theme="snow"
                  onChange={(e) => setItemLongDesc(e)}
                  className="border mt-3 rounded"
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={addNewItem}>
            Add
          </Button>
          <Button variant="secondary" onClick={onCloseAddItemDialog}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal show={cvTemplateDialog} onHide={closeCvTemplateDialog} backdrop="static" size="xl">
        <ModalHeader closeButton  style={{ backgroundColor: "#ccd2da" }}>Assign CV template</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              Template name:
            </Col>
            <Col md={4}>
              <FormControl type="text" onChange={e => setTemplateName(e.target.value)}/>
            </Col>
            <Col md={2}>
              <Button variant="primary" onClick={e => {
                if(cvPageIndex !== 0){
                  setCvPageIndex(0)
                }else{
                  searchTemplate();
                }
              }}>Search</Button>
            </Col>
          </Row>
          <Row style={{marginTop: "15px"}}>
            <Col md={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Template name</th>
                    <th style={{textAlign: "center"}}>View</th>
                  </tr>
                </thead>
                <tbody>
                  {templates.length <= 0 &&
                    <tr>
                      <td colSpan={3} style={{textAlign: "center"}}>
                        No data
                      </td>
                    </tr>
                  }
                  {templates &&
                    <>
                      {templates.map((cv) => {
                        return (
                          <tr>
                            <td style={{textAlign: "center"}}>
                              <FormCheck type="radio" value={cv.id} name="cv-template" onChange={ e => {
                                if(e.target.checked){
                                  setCvTemplateId(e.target.value)
                                }
                              }}/>
                            </td>
                            <td>
                              {cv.id}
                            </td>
                            <td>
                              {cv.templateName}
                            </td>
                            <td style={{textAlign: "center"}}>
                              <FontAwesomeIcon icon={faEye} onClick={e => {
                                getTemplate(cv.id);
                              }}/>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col md={4}>
              <PaginationComp
                total={cvTotalItem}
                pageSize={cvPageSize}
                callback={handleCvPaginationCallback}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant="info" onClick={closeCvTemplateDialog}>Close</Button>
          <Button variant="success" onClick={e => {
            if(cvTemplateId === ""){
              displayNotification("You must choose a template", "danger");
              return;
            }
            assignTemplate();
          }}>
            {cvAction === "new" &&
              <>Save</>
            }
            {cvAction === "edit" &&
              <>Update</>
            }
          </Button>
        </ModalFooter>
      </Modal>
      <Modal show={pkgStatusDialog} onHide={closePkgStatusDialog}>
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>Update package status</ModalHeader>
        <ModalBody>
          <FormSelect defaultValue={pkgStatus} onChange={e => setPkgStatus(e.target.value)}>
            <option value="actived-ready">Active Ready</option>
            <option value="actived-pending">Active Pending</option>
            <option value="deactived">Deactive</option>
          </FormSelect>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={updatePackageStatus}>Update</Button>
          <Button variant="secondary" onClick={closePkgStatusDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal show={pkgActiveDateDialog} onHide={closePkgActiveDateDialog}>
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>Set active date for package</ModalHeader>
        <ModalBody>
          <DatePicker value={pkgActiveDate} onChange={setPkgActiveDate} minDate={new Date()} calendarIcon={<FontAwesomeIcon icon={faCalendar}/>}/>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={updatePackageActiveDate}>Update</Button>
          <Button variant="secondary" onClick={closePkgActiveDateDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal show={viewCvTemplateDialog} onHide={closeViewCvTemplateDialog} backdrop="static" size="xl">
        <ModalHeader closeButton style={{ backgroundColor: "cadetblue" }}>Template detail</ModalHeader>
        <ModalBody>
          <FormBuilderComp data={cvTemplate}/>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={closeViewCvTemplateDialog}>Close</Button>
        </ModalFooter>
      </Modal>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          zIndex: 9999,
        }}
      >
        <ClockLoader loading={loading} color="red" />
      </div>
    </React.Fragment>
  );
};

export default PackageDetail;
