import { sendGetRequest, sendPostRequest } from "./service"

export const createSystemMessageRequest = async(title, content, activedFrom, activedTo, shortDescription, notificationType = "system") => {
    let params = {
        title: title,
        content: content,
        activedFrom: activedFrom,
        activedTo: activedTo,
        notificationType: notificationType,
        shortDescription: shortDescription
    }
    return await sendPostRequest("post", "/api/private/admin/message", params);
}

export const listSystemMessageRequest = async(title, fromDate, toDate, notificationType, pageIndex, pageSize) => {
    let params = {
        fromDate: fromDate,
        toDate: toDate,
        pageIndex: pageIndex,
        pageSize: pageSize
    }
    if(notificationType !== "all"){
        params.notificationType = notificationType
    }
    if(title){
        params.title = title
    }
    return await sendGetRequest("/api/private/admin/message", params);
}

export const getSystemMessageRequest = async(id) => {
    return await sendGetRequest("/api/private/admin/message/" + id);
}

export const updateSystemMessageRequest = async(id, title, content, activedFrom, activedTo, notificationType, shortDescription) => {
    let params = {
        messageId: id
    }
    if(title){
        params.title = title;
    }
    if(content){
        params.content = content;
    }
    if(activedFrom){
        params.activedFrom = activedFrom
    }
    if(activedTo){
        params.activedTo = activedTo;
    }
    if(notificationType){
        params.notificationType = notificationType
    }
    params.shortDescription = shortDescription;
    return await sendPostRequest("put", "/api/private/admin/message", params);
}

export const deleteSystemMessageRequest = async(id) => {
    return await sendPostRequest("delete", "/api/private/admin/message", {messageId: id});
}