import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormCheck, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "react-bootstrap";
import DatePicker from "react-date-picker";
import Notification from "../components/Notification";
import PaginationComp from "../components/PaginationComp";
import { assignCareGiverRequest, cancelCalendarRequest, getCalendarRequest, listCalendarRequest, updateCalendarRequest } from "../services/calendarService";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faEdit, faEye, faL, faUserNurse } from "@fortawesome/free-solid-svg-icons";
import Header from "../layouts/Header"
import { searchCareGiverRequest } from "../services/caregiverService";
import { ClockLoader } from "react-spinners";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { userDetailRequest } from "../services/userService";
import { isEmpty } from "lodash";

export default function CalendarManagement(){
    const now = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 30);
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [calendars, setCalendars] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalItem, setTotalItem] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success"); 
    const [loading, setLoading] = useState(false);
    const [filterByDate, setFilterByDate] = useState(false);

    const [fromDate, setFromDate] = useState(yesterday);
    const [toDate, setToDate] = useState(tomorrow);
    const [status, setStatus] = useState("new");
    const [paymentStatus, setPaymentStatus] = useState("all");
    const [searchById, setSearchById] = useState("");
    const [assignCaregiverDialog, setAssignCaregiverDialog] = useState(false);
    const [selectedCalendar, setSelectedCalendar] = useState({});
    const [caregivers, setCaregivers] = useState([]);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [careGiverPageIndex, setCareGiverPageIndex] = useState(0);
    const [careGiverPageSize, setCareGiverPageSize] = useState(10);
    const [careGiverTotal, setCareGiverTotal] = useState(0);
    const [assignCareGiver, setAssignCareGiver] = useState({});

    const [assignConfirmDialog, setAssingConfirmDialog] = useState(false);

    const [mannualPriceDialog, setMannualPriceDialog] = useState(false);
    const [mannualPrice, setMannualPrice] = useState(0);
    const [currentCalendarId, setCurrentCalendarId] = useState("")

    const [calendarDialog, setCalendarDialog] = useState(false);
    const [calendarDatetime, setCalendarDatetime] = useState(new Date());

    const [careGiverDialog, setCareGiverDialog] = useState(false);
    const [careGiverDetail, setCareGiverDetail] = useState({})

    const [cancelCalendarDialog, setCancelCalendarDialog] = useState(false);

    const closeCancelCalendarDialog = async() => {
        setCancelCalendarDialog(false);
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await listCalendar();
        }
    }

    const closeCareGiverDialog = () => {
        setCareGiverDialog(false);
    }

    const closeCalendarDialog = async() => {
        setCalendarDialog(false);
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await listCalendar();
        }
    }

    const closeMannualPriceDialog = async() => {
        setMannualPriceDialog(false);
    }

    const openMannualPriceDialog = async() => {
        setMannualPriceDialog(true)
    }


    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
        setLoading(false)
    }
    const handlePaginationCallback = (size, index) => {
        setPageSize(size);
        setPageIndex(index);
    }
    const handleCareGiverPaginationCallback = (size, index) => {
        setCareGiverPageSize(size);
        setCareGiverPageIndex(index)
    }
    useEffect(() => {
        listCalendar();
    }, [pageIndex]);

    const onCloseAssignCaregiverDialog = () => {
        setAssignCaregiverDialog(false);
    }
    const onOpenAssignCaregiverDialog = (calendar) => {
        setSelectedCalendar(calendar);
        setAssignCaregiverDialog(true);
    }
    const listCalendar = async() => {
        setLoading(true)
        const fDate = new Date(fromDate);  
        let tDate = new Date(toDate);
        let fd = (DateTime.fromJSDate(fDate)).toISODate();   
        let td = DateTime.fromJSDate(tDate).toISODate();  
        if(!filterByDate){
            fd = null;
            td = null;
        }
        const data = await listCalendarRequest(status, searchById, fd, td, pageIndex, pageSize, paymentStatus);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        for(let i = 0; i < data.items.length; i++){
            let date = data.items[i].startDatetime ? DateTime.fromISO(data.items[i].startDatetime.slice(0, -1)) : "N/A";
            date = date.toLocaleString(DateTime.DATETIME_SHORT);
            data.items[i].dateTime = date;
        }
        setCalendars(data.items);
        setTotalItem(data.total);
        setLoading(false)
    }
    const onStatusChange = (event) => {
        if(event.target.checked){
            setStatus(event.target.value)
        }
    }

    const onPaymentStatusChange = (event) => {
        if(event.target.checked){
            setPaymentStatus(event.target.value)
        }
    }
    const searchCareGiver = async() => {
        setLoading(true)
        const data = await searchCareGiverRequest(phoneNumber, careGiverPageIndex, careGiverPageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setCareGiverTotal(data.total);
        setCaregivers(data.items)
        setLoading(false)
    }

    useEffect(() => {
        searchCareGiver()
    }, [careGiverPageIndex])
    const assignCareGiverAction = async() => {
        setLoading(true)
        const data = await assignCareGiverRequest(selectedCalendar.id, assignCareGiver.id);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setLoading(false);
        closeAssignConfirmDialog()
    }
    const closeAssignConfirmDialog = async() => {
        setAssingConfirmDialog(false);
        onCloseAssignCaregiverDialog()
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await listCalendar();
        }
    }
    const updateCalendar = async() => {
        setLoading(true)
        const data = await updateCalendarRequest(currentCalendarId, mannualPrice, calendarDatetime);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCalendarDialog();
        closeMannualPriceDialog();
    }
    const getUser = async(id) => {
        setLoading(true)
        const data = await userDetailRequest(id);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setCareGiverDetail(data.user);
        setLoading(false)
    }
    const cancelCalendar = async() => {
        const data = await cancelCalendarRequest(currentCalendarId);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeCancelCalendarDialog();
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row>
                        <Col md={2}>
                            <FormCheck type="checkbox" label="Filter by date" onChange={e => {
                                setFilterByDate(e.target.checked)
                            }}/>
                        </Col>
                        <Col md={1}>From:</Col>
                        <Col md={2}>
                            <DatePicker value={fromDate} onChange={setFromDate} disabled={!filterByDate}/>                   
                        </Col>
                        <Col md={1}>To:</Col>
                        <Col md={2}>
                            <DatePicker value={toDate} onChange={setToDate} disabled={!filterByDate}/>                   
                        </Col>
                        <Col md={1}>Search by id:</Col>
                        <Col md={2}>
                            <FormControl type="text" onChange={e => setSearchById(e.target.value)}/>                 
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={2}>
                            Calendar status:
                        </Col>
                        <Col md={4}>
                            <FormCheck onChange={onStatusChange} type="radio" value="all" label="All" inline name="calendar-status"/>
                            <FormCheck onChange={onStatusChange} type="radio" value="cancel" label="Cancel" inline  name="calendar-status"/>
                            <FormCheck onChange={onStatusChange} type="radio" value="new" label="New" inline  name="calendar-status" defaultChecked/>
                            <FormCheck onChange={onStatusChange} type="radio" value="reject" label="Rejected" inline  name="calendar-status"/>
                            <FormCheck onChange={onStatusChange} type="radio" value="confirm" label="Confirmed" inline  name="calendar-status"/>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={2}>
                            Payment status:
                        </Col>
                        <Col md={4}>
                            <FormCheck onChange={onPaymentStatusChange} type="radio" value="all" label="All" inline name="payment-status" defaultChecked/>
                            <FormCheck onChange={onPaymentStatusChange} type="radio" value="PENDING" label="Pending" inline  name="payment-status"/>
                            <FormCheck onChange={onPaymentStatusChange} type="radio" value="PAID" label="Paid" inline  name="payment-status" />
                        </Col>
                        <Col md={2}>
                            <Button variant="success" onClick={listCalendar}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        {/* <th>User</th> */}
                                        <th>Phonenumber</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Payment Status</th>
                                        <th>CareGiver</th>
                                        <th>Service name</th>
                                        <th style={{textAlign: "center"}}>Length</th>
                                        <th style={{textAlign: "center"}}>Cost (VND)</th>
                                        <th style={{textAlign: "center"}}>Mannual Cost (VND)</th>
                                        <th style={{textAlign: "center"}}>Payment Amount</th>
                                        <th style={{textAlign: "center"}}>Cancel</th>
                                        <th style={{textAlign: "center"}}>Assign caregiver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {calendars &&
                                        <>
                                            {calendars.map((calendar) => {
                                                const payment = calendar.payment;
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {calendar.id}
                                                            </td>
                                                            {/* <td>
                                                                {calendar.user?.fullName ? calendar.user?.fullName : "N/A"}
                                                            </td> */}
                                                            <td>
                                                                {calendar.user?.phoneNumber}
                                                            </td>
                                                            <td>
                                                                <FontAwesomeIcon icon={faEdit} onClick={e => {
                                                                    setCalendarDatetime(calendar.startDatetime);
                                                                    setMannualPrice((calendar.mannualCost));
                                                                    setCurrentCalendarId(calendar.id)
                                                                    setCalendarDialog(true);
                                                                }}/> &nbsp;
                                                                {calendar.dateTime}
                                                            </td>
                                                            <td>
                                                                {calendar.status}
                                                            </td>
                                                            <td>
                                                                {payment?.status}
                                                            </td>
                                                            <td>
                                                                {calendar.careGiver &&
                                                                    <>
                                                                        <FontAwesomeIcon icon={faEye} onClick={e => {
                                                                            getUser(calendar.careGiver.id)
                                                                            setCareGiverDialog(true);
                                                                        }}/> &nbsp;
                                                                    </>
                                                                }
                                                                {calendar.careGiver?.phoneNumber}
                                                            </td>
                                                            <td>
                                                                {calendar.packageItem?.itemName}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {calendar.length}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {calendar.cost.toLocaleString()}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {calendar.mannualCost} &nbsp; <FontAwesomeIcon icon={faEdit}  onClick={e => {
                                                                        setCurrentCalendarId(calendar.id);
                                                                        setMannualPrice(calendar.mannualCost);
                                                                        setCalendarDatetime(calendar.startDatetime);
                                                                        openMannualPriceDialog()
                                                                    }
                                                                }/>
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {payment?.amount?.toLocaleString()}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faCancel} onClick={e => {
                                                                    setCurrentCalendarId(calendar.id);
                                                                    setCancelCalendarDialog(true);
                                                                }}/>
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                {calendar.status !== "confirm" && calendar.status !== "cancel" &&
                                                                    <FontAwesomeIcon icon={faUserNurse} size="lg"  onClick={e => onOpenAssignCaregiverDialog(calendar)}/>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={assignCaregiverDialog} onHide={onCloseAssignCaregiverDialog} size="xl" backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
                    Select a caregiver
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={3}>
                                Caregiver's phoneNumber:
                            </Col>
                            <Col md={4}>
                                <FormControl type="number" onChange={e => setPhoneNumber(e.target.value)} size="sm"/>
                            </Col>
                            <Col md={2}>
                                <Button variant="primary" onClick={searchCareGiver} size="sm">Search</Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "15px"}}>
                            <Col md={12}>
                                <table className="table table-bordered table-hover table-striped table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Full name</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th style={{textAlign: "center"}}>Assign</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {caregivers && 
                                        <>
                                            {caregivers.map((caregiver) => {
                                                return(
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {caregiver.fullName}
                                                            </td>
                                                            <td>
                                                                {caregiver.phoneNumber}
                                                            </td>
                                                            <td>
                                                                {caregiver.email}
                                                            </td>
                                                            <td style={{textAlign: "center"}}>
                                                                <Button variant="success" size="sm" onClick={e => {
                                                                    setAssignCareGiver(caregiver);
                                                                    setAssingConfirmDialog(true)
                                                                }}>Assign</Button>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                            
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <PaginationComp total={careGiverTotal} pageSize={careGiverPageSize}  callback={handleCareGiverPaginationCallback}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
            <Modal show={assignConfirmDialog} onHide={closeAssignConfirmDialog} backdrop="static" style={{borderWidth: "1px"}}>
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>
                    Assign the calendar to this {assignCareGiver.fullName ? assignCareGiver.fullName : assignCareGiver.phoneNumber}?
                </ModalHeader>
                <ModalFooter>
                    <Button variant="success" onClick={assignCareGiverAction}>Yes</Button>
                    <Button variant="secondary" onClick={closeAssignConfirmDialog}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal show={mannualPriceDialog} onHide={closeMannualPriceDialog} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>
                    Update calendar's cost
                </ModalHeader>
                <ModalBody>
                    <Container fluid="md">
                        <Row>
                            <Col md={3}>
                                New price:
                            </Col>
                            <Col md={6}>
                                <FormControl value={mannualPrice} type="number" min={0} step={100} onChange={e => setMannualPrice(e.target.value)}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="success" onClick={updateCalendar}>Update</Button>
                    <Button variant="secondary" onClick={closeMannualPriceDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal show={calendarDialog} onHide={closeCalendarDialog} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>
                    Update calendar's time
                </ModalHeader>
                <ModalBody>
                    <Container fluid>
                        <Row>
                            <Col md={12} style={{textAlign: "center"}}>
                                <DateTimePicker value={calendarDatetime} onChange={setCalendarDatetime}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="success" onClick={updateCalendar}>Update</Button>
                    <Button variant="secondary" onClick={closeCalendarDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal show={careGiverDialog} onHide={closeCareGiverDialog} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>
                    CareGiver detail
                </ModalHeader>
                <ModalBody>
                    <Container fluid>
                        <Row>
                            <Col md={5}>
                                CareGiver full name:
                            </Col>
                            <Col md={4}>
                                {careGiverDetail.fullName ? careGiverDetail.fullName : "N/A"}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={5}>
                                CareGiver phone number:
                            </Col>
                            <Col md={4}>
                                {careGiverDetail.phoneNumber}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={closeCareGiverDialog}>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal show={cancelCalendarDialog} onHide={closeCancelCalendarDialog} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
                    Cancel calendar event
                </ModalHeader>
                <ModalBody>
                    Do you want to cancel this event?
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={cancelCalendar}>yes</Button>
                    <Button variant="secondary" onClick={closeCancelCalendarDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            <div
                style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 9999,
                }}
            >
                <ClockLoader loading={loading} color="red" />
            </div>
        </React.Fragment>
    )
}