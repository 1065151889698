import { sendGetRequest, sendPostRequest } from "./service"

export const createCvTemplateRequest = async(template, templateName, status = true) => {
    let params = {
        template: template,
        templateName: templateName
    }
    params.status = status;
    return await sendPostRequest("post", "/api/private/admin/cv/template", params);
}

export const getCvTemplateRequest = async(id) => {
    return await sendGetRequest("/api/private/admin/cv/template/" + id);
}

export const updateCvTemplateRequest = async(templateId, template, templateName) => {
    let params = {
        templateId: templateId,
        template: template
    }
    if(templateName){
        params.templateName = templateName
    }
    return await sendPostRequest("put", "/api/private/admin/cv/template", params)
}

export const listCvTemplateRequest = async(templateName, status, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size
    }
    if(templateName){
        params.templateName = templateName
    }
    if(status !== "all"){
        params.status = status
    }
    return await sendGetRequest("/api/private/admin/cv/template", params);
}