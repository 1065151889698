import React from "react";
import Header from "../../layouts/Header";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Table,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { userDetailRequest } from "../../services/userService";
import Notification from "../../components/Notification";
import { useState } from "react";

const UserMemberDetail = () => {
  const [queryParams] = useSearchParams();

  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const userId = queryParams.get("id");

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };
  const closeNotification = () => {
    setShowToast(false);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={12}></Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">User management</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">User detail: ...</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">User family members</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Member detail: ...
                </li>
              </ol>
            </nav>
          </Row>

          <Row className="mt-3">
            <b className="fs-4">Member Detail: ...</b>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Table bordered striped>
                <thead className="table-primary">
                  <tr style={{ fontWeight: "bold" }}>
                    <th>Content</th>
                    <th>Detail</th>
                    <th style={{ textAlign: "center" }}>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Created time</td>
                    <td>2023-09-09 11:11:11</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Status</td>
                    <td>...</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Name</td>
                    <td>Trần Minh Hà</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Gender</td>
                    <td>...</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Birthday</td>
                    <td>...</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Phone</td>
                    <td>...</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Relationship</td>
                    <td>...</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />
    </React.Fragment>
  );
};

export default UserMemberDetail;
