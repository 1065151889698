import $ from "jquery";
import { createRef } from "react";
import { Component } from "react";
import { createCvTemplateRequest, updateCvTemplateRequest } from "../services/cvService";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");


//how to call class' function from other components: https://stackoverflow.com/questions/63250544/how-to-call-a-class-component-using-onclick-event-handler-in-react-js
//https://formbuilder.online/docs/formBuilder/options/disabledActionButtons/
class FormBuilderComp extends Component {
    action = this.props.action;
    formData = this.props.data;
    isInit = false
    fb = createRef();
    options = {
      // formData: formData,
      disabledActionButtons: ["data", "clear", "save"],
      disableFields: ["button", "file", "hidden", "starRating"],
      dataType: "json",
      disabledAttrs: ["access", "required", "className", "name", "placeholder"],
      fields: [
       
      ]
    }
    formControl;
    componentDidMount() {
      if(!this.isInit)  {
        console.log(this.formData)
        this.options.formData = this.formData
        this.formControl = $(this.fb.current).formBuilder(this.options);
        this.isInit = true;
      }
    }

    async saveForm(templateName){
      let template = await this.formControl.actions.getData();
      const  data = await createCvTemplateRequest(JSON.stringify(template), templateName);
      return data;
    }
    async updateForm(templateId, templateName){
      let template = await this.formControl.actions.getData();
      const data = await updateCvTemplateRequest(templateId, JSON.stringify(template), templateName);
      return data;
    }
  
    render() {
      return <div id="fb-editor" ref={this.fb} />;
    }
}

export default FormBuilderComp;