import { sendGetRequest, sendPostRequest } from "./service";

export const listPackageRequest = async (
  packageName,
  packageStatus,
  index,
  size
) => {
  let params = {
    pageSize: size,
    pageIndex: index,
  };
  if (packageName) {
    params.packageName = packageName;
  }
  if (packageStatus !== "all") {
    params.status = packageStatus;
  }
  return await sendGetRequest("/api/private/admin/package", params);
};
export const createPackageRequest = async (pkg) => {
  let params = {
    package: pkg,
  };
  return await sendPostRequest("post", "/api/private/admin/package", params);
};
export const updatePackageRequest = async (
  packageId,
  packageName,
  packageCode,
  shortDesc,
  longDesc,
  displayIndex,
  smallImage,
  largeImage,
  packageStatus,
  activeDate
) => {
  let params = {
    packageId: packageId,
  };
  if (packageName) {
    params.packageName = packageName;
  }
  if (shortDesc) {
    params.shortDesc = shortDesc;
  }
  if (longDesc) {
    params.longDesc = longDesc;
  }
  if (packageCode) {
    params.packageCode = packageCode;
  }
  if (smallImage) {
    params.smallImage = smallImage;
  }
  if (largeImage) {
    params.largeImage = largeImage;
  }
  if(packageStatus){
    params.packageStatus = packageStatus;
  }
  if(activeDate){
    params.activeDate = activeDate;
  }
  if(displayIndex){
    params.displayIndex = displayIndex;
  }
  return await sendPostRequest("PUT", "/api/private/admin/package", params);
};

export const createPackageItemRequest = async (item) => {
  let params = {
    item: item,
  };
  return await sendPostRequest("post", "/api/private/admin/package/item", params);
};

export const listPackageItemRequest = async (packageId) => {
  let params = {
    packageId: packageId,
  };
  return await sendGetRequest("/api/private/admin/package/item", params);
};
export const updatePackageItemRequest = async (
  itemName,
  itemCode,
  shortDesc,
  longDesc,
  itemId,
  smallImage,
  largeImage,
  unit,
  price,
  itemStatus,
  activeDate,
  displayIndex
) => {
  let params = {
    itemId: itemId,
  };
  if (itemName) {
    params.itemName = itemName;
  }
  if (itemCode) {
    params.itemCode = itemCode;
  }
  if (shortDesc) {
    params.shortDesc = shortDesc;
  }
  if (longDesc) {
    params.longDesc = longDesc;
  }
  if(smallImage){
    params.smallImage = smallImage
  }
  if(largeImage){
    params.largeImage = largeImage;
  }
  if(unit){
    params.unit = unit;
  }
  if(price){
    params.price = price
  }
  if(itemStatus !== null){
    params.itemStatus = itemStatus
  }
  if(activeDate){
    params.activeDate = activeDate
  }
  if(displayIndex){
    params.displayIndex = displayIndex;
  }
  return await sendPostRequest("PUT", "/api/private/admin/package/item", params);
};
export const deletePackageItemRequest = async (itemId) => {
  let params = {
    itemId: itemId,
  };
  return await sendPostRequest("delete", "/api/private/package/item", params);
};

export const getPackageDetailRequest = async (packageId) => {
  return await sendGetRequest("/api/private/admin/package/" + packageId);
};

export const getSubPackageDetailRequest = async (subPackageId) => {
  let params = {
    itemId: subPackageId,
  };
  return await sendGetRequest("/api/private/admin/package/item", params);
};
export const assignCvTemplateRequest = async(packageId, templateId) => {
  return await sendPostRequest("put", "/api/private/admin/package/cv", {
    packageId: packageId,
    templateId: templateId
  })
}
