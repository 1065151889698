import React from "react";

import UserManagement from "../UserManagement/UserManagement";
import CareGiverCvManagement from "../UserManagement/CareGiverCvManagement";
import PackageManagement from "../ServiceManagement/PackageManagement";
import CalendarManagement from "../CalendarManagement/CalendarManagement";
import ContactManagement from "../UserManagement/ContactManagement";
import UserDetail from "../UserManagement/NormalUserDetail/UserDetail";
import UserTotalBooking from "../UserManagement/NormalUserDetail/UserTotalBooking";
import UserBookingDetail from "../UserManagement/NormalUserDetail/UserBookingDetail";
import UserFamilyMember from "../UserManagement/NormalUserDetail/UserFamilyMember";
import UserMemberDetail from "../UserManagement/NormalUserDetail/UserMemberDetail";
import CareGiverTotalBooking from "../UserManagement/CareGiverDetail/CareGiverTotalBooking";
import CareGiverBookingDetail from "../UserManagement/CareGiverDetail/CareGiverBookingDetail";
import CaregiverServiceApplied from "../UserManagement/CareGiverDetail/CaregiverServiceApplied";
import CareGiverServiceAppliedDetail from "../UserManagement/CareGiverDetail/CareGiverServiceAppliedDetail";
import PackageDetail from "../ServiceManagement/PackageDetail";
import CvTemplateManagement from "../ServiceManagement/CvTemplateManagement";
import SubPackageDetail from "../ServiceManagement/SubPackageDetail";
import EmployeeManagement from "../UserManagement/EmployeeManagement";
import SystemMessage from "../MiscellaneousManagement/SystemMessage";

const protectedRoutes = [
  { path: "user/normal-user", element: <UserManagement /> },
  { path: "user/normal-user/:userId", element: <UserDetail /> },
  { path: "user/detail/totalbooking", element: <UserTotalBooking /> },
  { path: "user/detail/totalbooking/detail", element: <UserBookingDetail /> },
  { path: "user/detail/familymember", element: <UserFamilyMember /> },
  { path: "user/detail/familymember/detail", element: <UserMemberDetail /> },
  { path: "user/detail/caregivertotalbooking", element: <CareGiverTotalBooking /> },
  { path: "user/detail/caregivertotalbooking/detail", element: <CareGiverBookingDetail /> },
  { path: "user/detail/caregiverserviceapplied", element: <CaregiverServiceApplied /> },
  { path: "user/detail/caregiverserviceapplied/detail", element: <CareGiverServiceAppliedDetail /> },
  { path: "user/employee", element: <EmployeeManagement />},

  
  { path: "service/package", element: <PackageManagement /> },
  { path: "service/package/:packageId", element: <PackageDetail /> },
  { path: "service/subpackage/:subpackageId", element: <SubPackageDetail /> },
  { path: "service/cvtemplate", element: <CvTemplateManagement /> },


  { path: "user/cv", element: <CareGiverCvManagement /> },
  { path: "calendar/list", element: <CalendarManagement /> },
  { path: "user/contact", element: <ContactManagement /> },

  { path: "/system-message", element: <SystemMessage />}
];

export default protectedRoutes;
