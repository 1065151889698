import { sendGetRequest, sendPostRequest } from "./service";

export const loginRequest = async (email, password) => {
  let params = {
    email: email,
    password: password,
  };
  return await sendPostRequest("post", "/api/public/user/login", params);
};

export const listUserRequest = async (
  email,
  phoneNumber,
  userRole,
  dateOrder,
  pageIndex,
  pageSize
) => {
  let params = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    dateOrder: dateOrder
  };
  if (email) {
    params.email = email;
  }
  if (phoneNumber) {
    params.phoneNumber = phoneNumber;
  }
  if(userRole){
    params.role = userRole
  }
  return sendGetRequest("/api/private/admin/listUser", params);
};

export const userDetailRequest = async (userId) => {
  return sendGetRequest("/api/private/admin/user/" + userId);
};

export const listCvRequest = async (
  status,
  fromDate,
  toDate,
  pageIndex,
  pageSize
) => {
  let params = {
    pageSize: pageSize,
    pageIndex: pageIndex,
  };
  if (status !== "all") {
    params.status = status;
  }
  if (fromDate) {
    params.fromDate = fromDate;
  }
  if (toDate) {
    params.toDate = toDate;
  }
  return await sendGetRequest("/api/private/admin/caregiver/cvs", params);
};

export const changeUserRoleRequest = async (userId, roles) => {
  let params = {
    userId: userId,
    roles: roles,
  };

  return await sendPostRequest("PUT", "/api/private/admin/user/role", params);
};

export const listSystemRoleRequest = async() =>{
  return await sendGetRequest("/api/private/user/role");
}

export const listUserByRoleRequest = async(role, email, phoneNumber, index, size) => {
  let params = {
    role: role,
    email: email,
    phoneNumber: phoneNumber,
    pageIndex: index,
    pageSize: size
  }
  return await sendGetRequest("/api/private/admin/userByRole", params);
}

export const changeUserRoleStatusRequest = async(id, isActive) => {
  let params = {
    userRoleId: id,
    isActive: isActive
  }
  return await sendPostRequest("put", "/api/private/admin/user/role/status", params);
}

export const addFamilyMemberRequest = async(userId, memberType, surname, name, phoneNumber, dob, cityId, districtId, address, sex) => {
  let params = {
    userId: userId,
    memberType: memberType,
    surname: surname,
    name: name,
    phoneNumber: phoneNumber,
    dob: dob,
    cityId: cityId,
    districtId: districtId,
    address: address,
    sex: sex
  }
  return await sendPostRequest("post", "/api/private/admin/user/familyMember", params);
}

export const getFamilyMemberRequest = async(memberId) => {
  return await sendGetRequest("/api/private/admin/user/familyMember/" + memberId);
}

export const updateFamilyMemberRequest = async(memberId, memberType, surname, name, phoneNumber, dob, cityId, districtId,
  address, sex) => {
    let params = {
      memberId: memberId,
      memberType: memberType,
      surname: surname,
      name: name,
      phoneNumber: phoneNumber,
      dob: dob,
      cityId: cityId,
      districtId: districtId,
      address: address,
      sex: sex
    }
    return await sendPostRequest("put", "/api/private/admin/user/familyMember", params);
  }

  export const updateUserRequest = async(params, userId) => {
    let request = params;
    request.userId = userId;
    return await sendPostRequest("put", "/api/private/admin/user", request);
  }